.error-page {

    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    .logo-sec {
        .img-wrapper {
            width: 200px;
            padding: 30px 0px;
            @media (max-width:991px) {
            margin: 0 auto;
            }
        }
    }

    .content-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: left;
        height: calc(100vh - 95px);
        max-width: 900px;
        margin: 0 auto;
        .img-wrapper{
            width: 350px;
                   }
        h3 {
            color: #014e88;
            font-size: 48px;
            font-weight: 700;
            max-width: 440px;
            padding-bottom: 10px;

            @media (max-width:766px) {
                font-size: 27px;
            }
        }

        p {
            color: #202c35;
            font-size: 14px;
            max-width: 320px;
            padding-bottom: 10px;
        }

        .error-btn {
            background: #014e88;
            color: #fff;
            font-size: 14px;
            border-radius: 0;
            padding: 10px 30px;
            transition: .3s;

            &:hover {
                background-color: #0f71bc;
            }

            &:focus {
                box-shadow: none;
            }

            .icn {
                margin-top: -3px;
            }
        }
    }

    .d-center {
        display: flex;
        align-items: center;
    }

    @media (max-width:766px) {

        h3,
        p {
            margin: 0 auto;
            text-align: center;
            padding-bottom: 20px !important;

        }

        .btn-wrapper {
            text-align: center;

            .error-btn {
                padding: 7px 28px;
            }
        }

        .mb-reverse {
            flex-direction: column-reverse;
        }

        .content-wrapper {
            .img-wrapper {
                width: 250px;
             margin: 0 auto;
            }
        }
    }
}