
.about {
    .sub-banner {
        position: relative;
        background-image: url('../../images/about/abt-banner.png');
        width: 100%;
        height: 500px;
        background-position: center;
        background-size: cover;
        @media (max-width:991px) {
           display: flex;
           align-items: center;
           justify-content: center;
           }
        .breadcrum {
            @media (min-width:992px) {
            position: absolute;
            top: 50%;
            margin-top: -24px;
            right: 15%;
             font-weight: 700;
            }
            color: #fff;
          
        }
    }

    .main-content {
        padding: 70px 0px;

        @media (max-width:991px) {
            padding-top: 70px;
            padding-bottom: 20px;
        }

        p {
            font-size: 14px;
            line-height: 24px;
            padding-bottom: 20px;
            color: #3f3f3f;

            @media (max-width:991px) {
                text-align: justify;
            }
        }

        .green-bg {
            background-color: #00a6ce;
            color: #fff;
            font-size: 20px;
            padding: 0px 5px;
            margin-right: 5px;
        }

    }

    .our-team {
        .title {
            padding-bottom: 40px;

            @media (max-width:991px) {
                padding-bottom: 20px;
            }
        }
    }

    .team-content {
        padding-bottom: 70px;

        @media (max-width:991px) {
            padding-bottom: 20px;
        }

        .img-wrapper {
            border-radius: 50%;
            overflow: hidden;
            width: 125px;
            height: 125px;
            margin: 0 auto;

            @media (max-width:575px) {
                width: 100px;
                height: 100px;
            }

            @media (min-width:576px) and (max-width:767px) {
                width: 90px;
                height: 90px;
            }
        }

        .content-wrapper {
                    position: relative;
                    padding-top: 20px;
                    border: 1px solid #e7e7e7;
                    padding: 10px;
                    min-height: 200px;
                    &::after{
                        position: absolute;
                        content: '';
                        background-image: url('../../images/about/visiting-card-layout.png');
                        right: 0;
                        top: 0;
                        width: 150px;
                        height: 100%;
                        background-repeat: no-repeat;
                        background-size: contain;
                        @media (min-width:992px) and (max-width:1199px) {
                    width: 100px;
                        }
                        @media (max-width:575px) {
                            width: 100px;
                        }
                    }
            h5 {
                font-size: 15px;
                font-weight: 600;
                padding-bottom: 5px;
            }

            h6 {
                font-size: 13px;
                max-width: 200px;
                margin: 0 auto;
                padding-bottom: 10px;
                margin: 0;

                @media (max-width:767px) {
                    font-size: 14px;
                }
            }
              .phone {
                color: #636666;
                font-size: 12px;
                text-decoration: none;
                display: block;
                svg{
                    margin-right: 5px;
                }
            }
            .logo-wrapper{
                width: 150px;
                padding-bottom: 30px;
            }
            .profile{
                display: flex;
                .pro_pic{
                    width: 50px;
                    height: 50px;
                    overflow: hidden;
                    border-radius: 50%;
                    margin-right: 10px;
                    margin-bottom: 10px;
                }
                .name-group{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }
            }
            .linkedin{
                width: 25px;
                position: absolute;
                right: 10px;
                bottom: 12px;      
                 z-index: 2;

            }
            .ptb-9{
                padding: 9px 0px;
            }
        }

        .text-content {
            p {
                font-size: 14px;
                padding: 20px 0px;
                text-align: justify;
                color: #3f3f3f;
            }
        }
    }

    .plr-25 {
        padding: 0px 25px;
    }
    .team-slider{
        .next_icon {
            box-shadow: 0px 0 15px rgba(0, 0, 0, 0.1);
            background: #fff;
            border-radius: 25px;
            height: 36px;
            width: 36px;
            position: absolute;
            top: 200px;
            right: 0px;
            z-index: 1;
            padding: 12px 12px 12px 12px;
            @media (min-width:1200px) {
                right: -10px;
            }
            &:hover {
                background-color: #19315B;
                transition: 0.25s;

                polyline {
                    stroke: var(--white);
                }
            }

            &:focus {
                background-color: #19315B;
                transition: 0.25s;

                polyline {
                    stroke: var(--white);
                }
            }
          
        }
        .prev_icon {
            box-shadow: 0px 0 15px rgba(0, 0, 0, 0.1);
            background: #fff;
            border-radius: 25px;
            height: 36px;
            width: 36px;
            position: absolute;
            top: 200px;
            left: 0px;
            z-index: 1;
            padding: 12px 12px 12px 12px;

            @media (min-width:1200px) {
                left: -10px;
            }
            &:hover {
                background-color: #19315B;
                transition: 0.25s;

                polyline {
                    stroke: var(--white);
                }
            }

            &:focus {
                background-color: #19315B;
                transition: 0.25s;

                polyline {
                    stroke: var(--white);
                }
            }
        }
        @media (max-width:767px) {

            .next_icon,
            .prev_icon {
                height: 30px;
                width: 30px;
            }

            .next_icon {
                right: 0;
            }

            .prev_icon {
                left: 0;
            }

            .slider-wrapper {
                h4 {
                    font-size: 16px;
                }

                .img-wrapper {
                    height: 100% !important;

                    img {
                        width: 140px;
                    }
                }
            }
        }
        .slick-slider {
            .slick-prev {
                left: 0 !important;
                display: none !important;
            }
    
            .slick-next {
                right: 0 !important;
                display: none !important;
            }
        }
    }
    .mr-10{
        margin-right: 9px !important;
    }
}
.abt-main{
    .main-content{
        margin-top: -70px;
    }
  
}