.articles {
  .blog-img {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f9f9f9;
    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #0000006e;
    }
  }

  .main-content {
    .sub-section {
      padding: 50px 0px;

      h4 {
        font-size: 28px;
        font-weight: 600;

        @media (max-width: 767px) {
          font-size: 20px;
          padding-bottom: 15px;
        }
      }

      p {
        font-size: 14px;
        color: #8693a3;
      }

      h6 {
        font-size: 14px;
      }
    }

    .hrline {
      margin: 0;
      opacity: 1;
    }

    .description {
      padding: 50px 0px;
h1{
    font-size: 28px;
}
h2{
    font-size: 24px;
}
h3{
    font-size: 22px;
}
h4{
    font-size: 22px;
}
h4{
    font-size: 20px;
}
      p {
        font-size: 14px;
        color: #3f3f3f;
        padding-bottom: 20px;
        line-height: 24px;
        text-align: justify;
      }

      .p0 {
        padding: 0;
      }
    }

    .article-section {
      padding: 50px 0px;

      .title {
        h4 {
          font-size: 18px;
          margin-bottom: -20px;
        }
      }
    }

    .article-wrapper {
      margin: 0px 10px;
      box-shadow: 0 0 9px 1px rgba(215, 223, 231, 0.63);
      padding: 10px 20px;
      cursor: pointer;
      min-height: 337px;
      @media (min-width: 576px) and (max-width: 767px) {
        width: 100%;
      }
      .content-wrapper {
        h1,
        h2,
        h3,
        h4 {
          font-size: 16px;
        }
      }
      .img-wrapper {
        height: 160px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;

        @media (min-width: 576px) and (max-width: 767px) {
          width: 100%;
          height: 180px;
        }
      }

      .content-wrapper {
        h6 {
          font-size: 16px;
          padding: 10px 0px;
        }

        h5 {
          font-size: 19px;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          display: -webkit-box;
          overflow: hidden;
        }

        p {
          padding-top: 20px;
          font-size: 14px;
          color: #8693a3;
        }
      }
    }

    a {
      color: #000;
      text-decoration: none;
    }

    .pt-60 {
      padding-top: 50px;
    }
  }
}
