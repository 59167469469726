.footer {
    padding: 50px 0px;
    background-color: #000822;
    background-image: url('../../images/home/banner-vector.png');
    width: 100%;
    height: 400px;
    background-repeat: no-repeat;
    background-size: cover;

    @media (max-width:991px) {
        height: 100%;
    }

    .footer-left {
        .logo-wrapper {
            width: 150px;
            padding-bottom: 40px;
        }

        .content-wrapper {
            color: #c1c5ca;
            font-size: 14px;
            line-height: 26px;

            @media (min-width:992px) {
                max-width: 370px;
            }

            @media (max-width:991px) {
                padding-bottom: 10px;
            }
        }
        a{
            color: #08a9cf;
        }
    }

    .footer-right {
        form {
            margin-bottom: 10px;

            input {
                background-color: #1a2036;
                padding: 10px 15px;
                border-radius: 3px;
                border-color: #000822;
                color: #fff;


                &:focus {
                    box-shadow: none;
                    border-color: #000822;
                }



                &::placeholder {
                    color: #cbd0d9;
                    font-size: 14px;
                }
            }

            .subscribe-btn {
                background-color: #08a9cf;
                color: #fff;
                font-size: 14px;
                border-color: #08a9cf;
                border-radius: 3px;
                padding: 10px 20px;
                width: 100%;
                transition: .5s;

                &:focus {
                    box-shadow: none;
                    border-color: #08a9cf;

                }

                &:hover {
                    background-color: #0b7f9b;
                }
            }
        }

        .newsletter {
            cursor: pointer;
            font-size: 14px;
            color: #bbc2d0;
            text-decoration: underline;

        }

        .footer-contact {
            .location {
                padding-top: 40px;

                h6 {
                    color: #fff;
                    font-weight: 500;
                    font-size: 19px;
                    padding-bottom: 20px;
                }

                p {
                    color: #c1c5ca;
                    font-size: 14px;
                }

                a {
                    color: #08a9cf;
                    text-decoration: none;
                    transition: .5s;

                    &:hover {
                        color: #0988a4;
                    }
                }
            }

            .social-icons {
                padding-top: 40px;

                h6 {
                    color: #fff;
                    font-weight: 500;
                    font-size: 19px;
                    padding-bottom: 20px;
                }

                ul {
                    display: flex;
                    list-style-type: none;

                    li {
                        padding-right: 20px;

                        .img-wrapper {
                            width: 30px;
                            height:30px;
                            display: block;
                            background: #fff;
                            padding: 7px;
                            border-radius: 50%;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            img {
                                margin-left:1px;
                            }
                        }
                    }
                }
            }
        }
    }

    .copyright-sec {
        padding-top: 90px;

        p {
            color: #919db0;
            font-size: 14px;

            @media (max-width:991px) {
                text-align: center;
            }
        }

        .txt-right {
            text-align: right;

            @media (max-width:991px) {
                text-align: center;
            }
            a{
                color: #08a9cf;
                text-decoration: none;
                transition: .3s;
                &:hover{
                    color: #1ec1e6;
                }
            }
        }
    }

    @media (max-width:767px) {
        text-align: center;

        .logo-wrapper {
            margin: 0 auto;
        }

        .footer-right {
            .footer-contact {
                .social-icons {
                    ul {
                        justify-content: center;
                    }
                }
            }
        }
    }
}

// sticky btn start
.sticky-btn {
    .buttonLink {
        color: #fff;
    }

    .verticalButton {
        padding: 0px 0px 0px 0px;
        width: 170px;
        height: 40px;
        background: #08a9cf;
        color: #fff;
        border-radius: 0px 0px 5px 5px;
        -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        -webkit-transform: rotate(270deg);
        position: fixed;
        right: -65px;
        top: 45%;
        border-radius: 0;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        transition: .5s;

        &:focus {
            box-shadow: none;
        }

        &:hover {
            background-color: #0b7f9b;
        }
    }
}

// sticky btn end