@import url('https://fonts.cdnfonts.com/css/myriad-pro');

html {
    scroll-behavior: smooth;
}

:root {
    --black: #0C0B0B;
    --white: #ffffff;
    --blue: #25aae1;
    --green: #9bd704;
    --primary_bg: #002f43;

}

.container {
    width: 100%;
    max-width: 1200px;
    padding: 0 15px;
    margin: 0 auto;

}

body {
    font-family: 'Poppins', sans-serif;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.dv-center {
    display: flex;
    align-items: center;
}

.container-fluid {
    overflow: hidden
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
ul {
    padding: 0;
    margin: 0
}

.p0 {
    padding: 0
}

h2 {
    font-weight: 700;
    color: #02161a;
    font-size: 34px;
    padding-bottom: 20px;
}

.plr-5 {
    padding: 0px 5px;
}

.prim-btn {
    font-size: 14px;
    color: #014e88;
    border: 2px solid #ebf4f9;
    padding: 9px 20px;
    width: 130px;
    margin-top: 25px;
    transition: .5s;
    background-color: #fff;

    &:focus {
        box-shadow: none;
    }

    &:hover {
        border-color: #014e88
    }
}


@media (min-width:992px) {
    .t-right {
        text-align: right
    }
}
.mrg-70{
    margin-top: -70px
}
.mt-50{
    margin-top: 50px;
}
.scroll-to-top {
    background-color: rgb(0 166 206) !important;
}
.pt-50{
    padding-top: 70px
}
.pb-15{
    padding-bottom: 15px;
}
.toast-position {
    top: 4rem !important;
  }
// banner down arrow
.sub-down-arrow{
    bottom: 30px;
    position: absolute;
    border: 1px solid #fff;
    width: 40px;
    height: 40px;
    padding: 5px;
    border-radius: 50%;
    left: 50%;
    margin-left: -15px;
    box-shadow: 0px 0px 5px rgba(12, 33, 59, 0.3411764);
display: flex;
    align-items: center;
    justify-content: center;
    animation-duration: 4s !important;
    animation-iteration-count: infinite !important;
    animation-name: bounce-3 !important;
    animation-timing-function: ease !important;
    svg{
        font-size: 18px;
        color: #fff;
    }
}
@keyframes bounce-3 {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-15px);
    }

    100% {
        transform: translateY(0);
    }
}
// banner down arrow

// Component-styles
@import url(./component-styles/header.scss);
@import url(./component-styles/footer.scss);