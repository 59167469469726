.clients {
    padding-bottom: 100px;

    .title {
        padding-bottom: 30px;

        h3 {
            padding-bottom: 0px;
        }

        h2 {
            span {
                position: relative;

                @media (min-width: 991px) {
                    &:before {
                        position: absolute;
                        content: "";
                        top: 50%;
                        margin-top: -1px;
                        left: -500px;
                        width: 480px;
                        height: 2px;
                        background-color: #e4e8ec;
                    }

                    &:after {
                        position: absolute;
                        content: "";
                        top: 50%;
                        margin-top: -1px;
                        right: -500px;
                        width: 480px;
                        height: 2px;
                        background-color: #e4e8ec;
                    }
                }

                @media (min-width: 992px) and (max-width: 1199px) {
                    &:before {
                        width: 400px;
                        left: -410px;
                    }

                    &:after {
                        width: 400px;
                        right: -410px;
                    }
                }
            }
        }
    }

    .slider-wrapper {
        .img-wrapper {
            height: auto !important;
            display: flex;
            justify-content: center;
        }
    }



    @media (max-width: 767px) {
        padding-bottom: 50px;

        .slick-slider {
            button {
                display: none !important;
            }
        }
    }

    .slick-slider {
        .slick-prev {
            left: 0 !important;
            display: none !important;
        }

        .slick-next {
            right: 0 !important;
            display: none !important;
        }
    }

    .slick-dots li {
        margin: 0 0px !important;

        button:before {
            color: #00afff !important;
            font-size: 10px;
        }
    }

    .slick-slider {
        padding: 0px 0px 30px 0px !important
    }
}