.team {
    padding: 70px 0px;

    .title {
        padding-bottom: 30px;
    }

    .main-content {
        .card {
            border: 0;
            box-shadow: 0px 0px 9px 1px rgb(215 223 231 / 63%);
            padding: 30px 0px;
            width: 100%;
            border-radius: 15px;

            @media (max-width:991px) {
                margin: 0 auto;
                margin-top: 20px;
            }
            .card-body{
                min-height: 435px;
            }
        }

        .img-wrapper {
            border-radius: 50%;
            overflow: hidden;
            width: 200px;
            height: 200px;
            margin: 0 auto;
            @media (max-width:450px) {
                width: 150px;
                height: 150px;
            }
        }

        .content-wrapper {
            text-align: center;
            padding-top: 20px;

            h5 {
                font-size: 18px;
                font-weight: 600;
            }

            h6 {
                font-size: 15px;
                max-width: 200px;
                margin: 0 auto;
                padding-bottom: 10px;
                height: 46px;

            }

            .mailid {
                color: #00a6ce;
                font-size: 14px;
                text-decoration: none;
            }

            .phone {
                color: #83a3b3;
                font-size: 14px;
                text-decoration: none;
            }
        }
    }
    .home-team-slider{
        .next_icon {
            box-shadow: 0px 0 15px rgba(0, 0, 0, 0.1);
            background: #fff;
            border-radius: 25px;
            height: 36px;
            width: 36px;
            position: absolute;
            top:50%;
            margin-top: -18px;
            right: 0px;
            z-index: 1;
            padding: 12px 12px 12px 12px;
            @media (min-width:1200px) {
                right: -10px;
            }
            &:hover {
                background-color: #19315B;
                transition: 0.25s;

                polyline {
                    stroke: var(--white);
                }
            }

            &:focus {
                background-color: #19315B;
                transition: 0.25s;

                polyline {
                    stroke: var(--white);
                }
            }
          
        }
        .prev_icon {
            box-shadow: 0px 0 15px rgba(0, 0, 0, 0.1);
            background: #fff;
            border-radius: 25px;
            height: 36px;
            width: 36px;
            position: absolute;
            top:50%;
            margin-top: -18px;
            left: 0px;
            z-index: 1;
            padding: 12px 12px 12px 12px;

            @media (min-width:1200px) {
                left: -10px;
            }
            &:hover {
                background-color: #19315B;
                transition: 0.25s;

                polyline {
                    stroke: var(--white);
                }
            }

            &:focus {
                background-color: #19315B;
                transition: 0.25s;

                polyline {
                    stroke: var(--white);
                }
            }
        }

        @media (max-width:767px) {

            .next_icon,
            .prev_icon {
                height: 30px;
                width: 30px;
            }

            .next_icon {
                right: 0;
            }

            .prev_icon {
                left: 0;
            }

            .slider-wrapper {
                h4 {
                    font-size: 16px;
                }

                .img-wrapper {
                    height: 100% !important;

                    img {
                        width: 140px;
                    }
                }
            }
        }
        .slick-slider {
            .slick-prev {
                left: 0 !important;
                display: none !important;
            }
    
            .slick-next {
                right: 0 !important;
                display: none !important;
            }
        }
        .team-wrap{
            padding:  20px;
        }
    }
    .p-12{
        padding: 12px 0px;
    }
}