.header {
    position: fixed;
    top: 0;
    width: 100%;
    background: #fff;
    z-index: 999;
    box-shadow: 1px 1px 8px 1px #85858533;
margin-bottom: 69px;
    .navbar {
        padding-top: 0;
        padding-bottom: 0;

        @media (max-width: 991px) {
            padding: 10px 0px;
        }

        button {
            &:focus {
                box-shadow: none;
            }
        }

        .navbar-brand {
            img {
                width: 150px;
            }
        }

        .navbar-collapse {
            .active {
                color: var(--blue) !important;
            }

            .nav-link {
                color: #303030;
                font-size: 14px;
                font-weight: 500;
                font-family: 'Myriad Pro', sans-serif;
            }

            @media (min-width: 992px) {
                display: flex;
                justify-content: flex-end;

                .nav-link {
                    padding: 24px 16px;
                    cursor: pointer;
                }
            }

            @media (max-width: 991px) {

                background: rgb(220 225 243);
                margin-top: 10px;

                .nav-link {
                    border-bottom: 1px solid #fff;
                    padding: 10px 20px;
                }

                .navbar-nav {
                    margin-top: 0 !important;
                    margin-bottom: 0 !important;
                }

            }

            .drop-head {
                &:hover {
                    .dropdown-menu {
                        display: block;
                    }
                }

            }

            .dropdown-menu {
                padding: 0;
                margin-top: -2px;
                border-radius: 0;

                .dropdown-item {
                    font-size: 14px;
                    padding: 10px 15px;
                    border-bottom: 1px solid #efebeb;
                    font-family: 'Myriad Pro', sans-serif;

                    &:active,
                    &:hover {
                        color: #000;
                        background-color: #e9ecef;
                    }
                }
            }
        }

        @media (max-width: 991px) {
            .navbar-toggler {
                padding: 4px 6px !important;

                .navbar-toggler-icon {
                    width: 22px;
                    height: 22px;
                }
            }
        }

    }

}