.contact {
    .banner {
        position: relative;
        background-image: url('../../images/contact/banner.png');
        width: 100%;
        height: 500px;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        align-items: center;
        background-position: right;

        .breadcrum {
            position: absolute;
            left: 10%;
            color: #fff;
            font-weight: 700;
            max-width: 400px;
        }
    }

    .main-content {
        padding: 50px 0px;

        .title {
            p {
                font-size: 14px;
            }
        }

        .content-wrapper {
            padding: 70px 0px;

            .email-label {
                p {
                    font-weight: 600;
                    font-size: 16px;

                    img {
                        padding-right: 10px;
                    }
                }
            }

            .email-content {
                padding: 20px 30px;
                height: 100px;
                display: flex;
                flex-direction: column;
                justify-content: center;

                h5 {
                    font-size: 14px;
                    font-weight: 600;
                }

                a {
                    padding-top: 10px;
                }

                a {
                    font-size: 14px;
                    font-weight: 400;
                    color: #000;
                    text-decoration: none;
                    transition: .5s;

                    &:hover {
                        color: #014e88;
                    }
                }

                .call_text {
                    font-size: 16px;
                    font-weight: 600;
                    padding-top: 0;
                }
            }

            .brder {
                border-top: 2px solid #eaf2f9;
                border-bottom: 2px solid #eaf2f9;
                border-left: 2px solid #eaf2f9;
            }

            @media (max-width:991px) {
                .brder {
                    border: 2px solid #eaf2f9;
                }
            }

            .full-brdr {
                border: 2px solid #eaf2f9;
            }

            @media (min-width:992px) {
                .r-brdr {
                    border-right: 2px solid #eaf2f9;
                }
            }

            .p-0 {
                padding: 0;
            }
        }

        .pt-20 {
            padding-top: 30px;
        }

        .hrline {
            margin: 0;
            opacity: 1;
        }

        .form-sec {
            padding: 70px 0px;

            .form {
                input {
                    font-size: 14px;

                    &:focus {
                        box-shadow: none;
                        border-color: #014e88;
                    }

                    // &::placeholder {
                    //     font-size: 14px;
                    //     color: #000;
                    // }
                }

                label {
                    font-size: 14px;
                    color: #000;
                    font-weight: 600;
                }

                .form-control {
                    border: 1px solid #000;
                }

                textarea {
                    font-size: 14px;

                    &:focus {
                        box-shadow: none;
                        border-color: #014e88;
                    }
                }

                .btn-wrapper {
                    margin-top: 30px;

                    .contact-btn {
                        background-color: #08a9cf;
                        color: #fff;
                        font-size: 14px;
                        border-color: #08a9cf;
                        width: 150px;
                        padding: 10px 10px;
                        transition: .3s;

                        &:focus {
                            box-shadow: none;
                        }

                        &:hover {
                            background-color: #0f88a3;
                            border-color: #0f88a3;
                        }
                    }
                }
            }
        }

        @media (max-width:991px) {
            .mpt-10 {
                padding-top: 10px !important;
                padding-left: 10px !important;
                padding-right: 10px !important;
            }
        }
    }
}