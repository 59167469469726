// ***************************************banner start***************************************
.home-page {
    .banner {
        position: relative;
        background-image: url('../../images/home/banner1.png');
        height: calc(100vh - 67px);
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;

        // .overlay {
        //     position: absolute;
        //     top: 0;
        //     left: 0;
        //     background: url('../../images/home/banner-vector.png') repeat-x;
        //     height: calc(100vh - 69px);
        //     width: 100%;
        //     background-repeat: no-repeat;
        //     background-size: cover;
        //     background-position: center;
        //     opacity: .5;
        //     animation: scroll-anim 5s linear infinite;
        // }

        // @keyframes scroll-anim {
        //     100% {
        //         background-position: -100% 0;
        //     }
        // }

        .banner-right {
            position: relative;

            h1,
            h3 {
                color: #fff;
                font-family: 'Myriad Pro', sans-serif;
            }

            h1 {
                font-weight: 700;
                font-size: 44px;
                padding: 15px 0px;
            }

            h3 {
                font-weight: 600;
                font-size: 26px;

                // @media (min-width:576px) {
                //     width: 27ch;
                //     animation: typing 2s steps(22), blink .5s step-end infinite alternate;
                //     white-space: nowrap;
                //     overflow: hidden;
                //     border-right: 3px solid;
                // }

                @media (max-width:991px) {
                    margin: 0 auto;
                }
            }

            // @keyframes typing {
            //     from {
            //         width: 0
            //     }
            // }

            // @keyframes blink {
            //     50% {
            //         border-color: transparent
            //     }
            // }

            h6 {
                color: #00a6ce;
                font-size: 16px;
                padding-bottom: 25px;
                font-weight: 500;
                font-family: 'Myriad Pro', sans-serif;
            }

            a {
                font-family: 'Poppins', sans-serif;

            }

            .banner-btn {
                color: #014e88;
                background-color: #fff;
                font-size: 14px;
                padding: 12px 10px;
                width: 160px;
                border-radius: 8px;
                font-weight: 600;
                transition: .5s;

                &:hover {
                    background-color: #014e88;
                    border-color: #fff;
                    color: #fff;
                }
            }

            @media (max-width:991px) {
                text-align: center;
            }

            @media (max-width:575px) {
                h1 {
                    font-size: 30px;
                }
            }
        }
            .down-arrow{
                bottom: 30px;
                position: absolute;
                border: 1px solid #fff;
                width: 40px;
                height: 40px;
                padding: 5px;
                border-radius: 50%;
                left: 50%;
                margin-left: -15px;
                box-shadow: 0px 0px 5px rgba(12, 33, 59, 0.3411764);
            display: flex;
                align-items: center;
                justify-content: center;
                animation-duration: 4s !important;
                animation-iteration-count: infinite !important;
                animation-name: bounce-2 !important;
                animation-timing-function: ease !important;
                cursor: pointer;
                svg{
                    font-size: 18px;
                    color: #fff;
                }
            }
            @keyframes bounce-2 {
                0% {
                    transform: translateY(0);
                }

                50% {
                    transform: translateY(-15px);
                }

                100% {
                    transform: translateY(0);
                }
            }
                }
}

// ***************************************banner End***************************************

// ***************************************section2 start***********************************
.section2 {
    padding: 70px 0px;

    .sec2-wrap {
        padding-bottom: 30px;
    }

    .img-wrapper {

        @media (min-width:992px) {
            margin-left: 70px;

            .c-img {
                height: 436px;
                position: relative;
            }
        }

        @media (min-width:992px) {
            .line-img {
                position: absolute;
                left: 250px;
                width: 270px;
                top: 50%;
                margin-top: -46px;
            }
        }

        @media (max-width:991px) {
            .line-img {
                position: absolute;
                top: 50%;
                margin-top: -25px;
                left: 110px;
                width: 200px;
            }
        }

        @media (max-width:991px) {
            position: relative;
            width: 300px;
            margin: 0 auto;
            margin-bottom: 30px;

            .ecg-line {
                position: absolute;
                top: 130px;
                right: 0;
                width: 200px;
            }
        }
    }

    .content-wrapper {
        p {
            color: #3f3f3f;
            font-size: 14px;
            line-height: 24px;
        }
    }

    .bg-blue {
        background-color: #014e88;
        padding: 30px 0px;
        margin-top: 50px;

        .content-wrapper {
            p {
                font-size: 28px;
                color: #fff;
                line-height: 40px;
                max-width: 900px;
                margin: 0 auto;

                @media (max-width:991px) {
                    text-align: center;
                }
            }
        }
    }

    @media (max-width:991px) {

        h2,
        p {
            text-align: center;
        }

        .btn-wrapper {
            text-align: center;
        }

    }

    // ecg line animation start
    .intro-block svg {
        max-width: 504px;
        margin-left: 20px;
        margin-right: 20px;
        position: absolute;
        transition: all 0.5s ease-in-out;
        left: 70px;
        width: 430px;
        top: 28px;
    }

    @media (min-width: 992px) and (max-width: 1199px) {
        .intro-block svg {
            left: 10px;
        }
    }

    .intro-block svg .ekg {
        fill: none;
        stroke: #b0c1d0;
        stroke-width: 3;
        stroke-linecap: round;
        stroke-linejoin: miter;
        opacity: 0;
        stroke-dashoffset: 1000;
        stroke-dasharray: 1000;
        animation: ekg 5s linear forwards infinite;
    }

    @keyframes ekg {
        0% {
            opacity: 0;
        }

        25% {
            opacity: 1;
        }

        50% {
            stroke-dashoffset: 2000;
        }

        99% {
            opacity: 0;
            stroke-dashoffset: 3000;
        }

        100% {
            stroke-dashoffset: 1000;
        }
    }

    .intro-block {
        top: -390px;
        position: relative;
        z-index: -1;
    }
}

// ecg line animation end
// ***************************************section2 end*************************************
// ***************************************section3 start*************************************
.section3 {
    position: relative;
    padding-top: 40px;
    padding-bottom: 70px;

    @media (max-width:991px) {
        h2 {
            text-align: center;
        }

        .btn-wrapper {
            text-align: center;
        }
    }

    @media (min-width:1300px) {
        .dotted-line {
            img {
                right: 36%;
                top: -65px;
                position: absolute;
                height: 724px;
                z-index: -1;
            }
        }
    }

    @media (min-width:992px) and (max-width:1299px) {
        .dotted-line {
            img {
                right: 32%;
                top: -73px;
                position: absolute;
                height: 814px;
                z-index: -1;
            }
        }
    }

    .left-content-wrapper {
        ul {
            list-style-type: none;
            padding-left: 25px;

            li {
                position: relative;
                font-size: 14px;
                padding-top: 15px;
                color: #000;
                text-align: justify;

                &:before {
                    position: absolute;
                    content: '';
                    top: 21px;
                    left: -25px;
                    width: 7px;
                    height: 8px;
                    background-image: url('../../images/home/Ellipse.svg');
                    background-repeat: no-repeat;
                    background-size: contain;
                }
            }
        }

        h6 {
            font-size: 16px;
            padding-bottom: 10px;
            color: #000;
            font-weight: 600;
        }

        .btn-wrapper {
            padding-left: 25px;
        }
    }

    .right-content-wrapper {
        @media (max-width:991px) {
            padding-top: 30px;

            h5 {
                text-align: center;
            }
        }

        p {

            font-size: 14px;
            color: #000;
            background: #fff;
            padding: 5px 0px;

            @media (min-width:992px) {
                max-width: 350px;
            }
        }

        .txt-right {
            text-align: right;
        }

        .contents {
            min-height: 120px;
            padding-bottom: 40px;
        }

        .content1 {
            img {
                width: 80px;
            }
        }

        .content2 {
            img {
                width: 80px;
            }
        }

        .content3 {
            img {
                width: 80px;
            }
        }

        h5 {
            color: #000;
            font-size: 22px;
            font-weight: 600;
            padding-bottom: 30px;
            background: #fff;
            padding: 5px 0px;

            @media (min-width:992px) {
                max-width: 221px;
            }
        }
    }
}

// ***************************************section3 end*************************************
// ***************************************section4 start***********************************
.section4 {
    background-color: #f4f8fb;
    padding: 70px 0px;

    .content-wrapper {
        ul {
            list-style-type: none;
            padding-left: 25px;

            li {
                position: relative;
                font-size: 14px;
                padding-top: 15px;
                color: #000;
                text-align: justify;

                &:before {
                    position: absolute;
                    content: '';
                    top: 21px;
                    left: -25px;
                    width: 7px;
                    height: 8px;
                    background-image: url('../../images/home/Ellipse.svg');
                    background-repeat: no-repeat;
                    background-size: contain;
                }
            }
        }

        .btn-wrapper {
            padding-top: 20px;
        }
    }

    @media (max-width:991px) {

        h2,
        .btn-wrapper {
            text-align: center;
        }
    }
}

// ***************************************section4 end*************************************