.rca {
    .sub-banner {
        position: relative;
        background-image: url('../../images/services/banner.png');
        width: 100%;
        height: 500px;
        background-position: center;
        background-size: cover;
        display: flex;
        align-items: center;

        .overlay {
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: rgb(0 0 0 / 46%);
        }

        .breadcrum {
            position: absolute;
            left: 10%;
            color: #fff;
            font-weight: 700;
            max-width: 400px;
        }
    }
.cpa-banner{
    position: relative;
    background-image: url('../../images/services/banner-2.png');
    width: 100%;
    height: 500px;
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: center;

    .overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgb(0 0 0 / 46%);
    }

    .breadcrum {
        position: absolute;
        left: 10%;
        color: #fff;
        font-weight: 700;
        max-width: 400px;
    }
}
    .main-content {
        padding: 70px 0px;

        @media (max-width:991px) {
            padding-top: 70px;
            padding-bottom: 20px;
        }

        p {
            font-size: 14px;
            line-height: 24px;
            padding-bottom: 20px;
            color: #3f3f3f;

            @media (max-width:991px) {
                text-align: justify;
            }
        }
.blue-para{
    color: #014e88;
}
        .green-bg {
            background-color: #00a6ce;
            color: #fff;
            font-size: 20px;
            padding: 0px 5px;
            margin-right: 5px;
        }

        .content-section {
            padding-top: 50px;

            .content-wrapper {
                .img-wrapper {
                    width: 60px;
                    height: 60px;
                    margin-bottom: 15px;
                }

                h5 {
                    font-size: 20px;
                    padding-bottom: 15px;
                }

            }
        }
    }

    .pt-25 {
        padding-top: 25px;
    }

    @media (max-width:991px) {
        .content-section {
            padding-top: 20px !important;
        }

        .mbt-20 {
            padding-top: 30px;
        }
    }
    @media (min-width:992px) {
.plr-30{
    padding: 0px 30px !important;
}
.plr-20{
    padding: 0px 20px !important;
}
    }
}
.listing-wrapper{
    padding-bottom: 30px;
    h6{
font-size: 18px;
    }
    p{
        margin-bottom: -20px !important;
    }
    ul {
        list-style-type: none;
        padding-left: 25px;
padding-bottom: 15px;
        li {
            position: relative;
            font-size: 14px;
            padding-top: 15px;
            color: #000;
            text-align: justify;

            &:before {
                position: absolute;
                content: '';
                top: 21px;
                left: -25px;
                width: 7px;
                height: 8px;
                background-image: url('../../images/home/Ellipse.svg');
                background-repeat: no-repeat;
                background-size: contain;
            }
        }
    }
    .blue-para{
        color: #014e88;
    }
}