.news {
  padding-bottom: 70px;

  @media (max-width: 991px) {
    text-align: center;
  }

  .title {
    font-size: 18px;
    padding-bottom: 10px;
  }

  .slider-wrapper-content {
    .slider-wrapper {
      margin: 10px;
      box-shadow: 0 0 9px 1px rgba(215, 223, 231, 0.63);
      padding: 10px 20px;
      .content {
        h1,
        h2,
        h3,
        h4 {
          font-size: 16px;
        }
      }
      .img-wrapper {
        height: 200px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 100%;
          height: auto;
          object-fit: cover;
        }
      }

      .content {
        h5 {
          font-size: 16px;
          margin-bottom: 5px;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          display: -webkit-box;
          overflow: hidden;
        }

        h4 {
          font-size: 19px;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          display: -webkit-box;
          overflow: hidden;
          margin-top: 20px;
          font-weight: 600;
        }

        p {
          color: #8693a3;
          font-size: 14px;
          padding-top: 15px;
        }
      }
    }

    .slick-prev {
      left: 0;
    }

    .slick-next {
      right: 0;
    }

    @media (max-width: 991px) {
      padding-top: 40px;
    }

    a {
      color: #000;
      text-decoration: none;
    }
    .description {
      p {
        font-size: 15px !important;
        color: #000 !important;
        padding-top: 10px !important;
        line-height: 20px;
      }
    }
  }
}

.all-article {
  .banner {
    position: relative;
    background-image: url("../../images/blog/all-article-banner.png");
    width: 100%;
    height: 500px;
    background-position: center;
    background-size: cover;

    .breadcrum {
      position: absolute;
      bottom: 86px;
      right: 20%;
      color: #fff;
      font-weight: 700;
    }
  }
  padding-bottom: 70px;
  .pt-20 {
    padding-top: 40px;
  }
}
